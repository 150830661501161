import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import * as styles from "./styles.module.scss"

export function Header() {
	const [match, setMatch] = React.useState(null)
	const [mobileMenu, setMobileMenu] = React.useState(false)

	const mobile = match
	React.useEffect(() => {
		function changeMatch() {
			const { matches } = window.matchMedia("(max-width:768px)")
			setMatch(matches)
		}
		changeMatch()
		window.addEventListener("resize", changeMatch)
		return () => {
			window.removeEventListener("resize", changeMatch)
		}
	}, [match])

	return (
		<header className={styles.headerContainer}>
			<div className="container column">
				{mobile && (
					<button
						aria-label="Menu"
						onClick={() => setMobileMenu(!mobileMenu)}
						className={`${styles.mobileButton} ${
							mobileMenu && styles.mobileButtonActive
						}`}
					></button>
				)}

				<div className={styles.logo}>
					<StaticImage
						src="../../images/logo-clifali.png"
						width={230}
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="logo_clifali"
						placeholder="tracedSVG"
						layout="constrained"
					/>
				</div>

				<nav
					className={`${mobile ? styles.menuNav : styles.menu} ${
						mobileMenu && styles.menuNavActive
					}`}
				>
					<Link to="/" activeClassName={styles.active}>
						home
					</Link>

					<Link to="/clinica" activeClassName={styles.active}>
						clínica
					</Link>
					<Link to="/#especialidades">especialidades</Link>
					<Link to="/#blog">blog</Link>
					<Link to="/#convenios">convênios</Link>
					<Link to="#contato">contato</Link>
				</nav>
			</div>
		</header>
	)
}
